// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-democontent-js": () => import("./../../../src/pages/democontent.js" /* webpackChunkName: "component---src-pages-democontent-js" */),
  "component---src-pages-facecloak-js": () => import("./../../../src/pages/facecloak.js" /* webpackChunkName: "component---src-pages-facecloak-js" */),
  "component---src-pages-hologram-js": () => import("./../../../src/pages/hologram.js" /* webpackChunkName: "component---src-pages-hologram-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magni-pi-js": () => import("./../../../src/pages/magni-pi.js" /* webpackChunkName: "component---src-pages-magni-pi-js" */),
  "component---src-pages-navbar-js": () => import("./../../../src/pages/navbar.js" /* webpackChunkName: "component---src-pages-navbar-js" */),
  "component---src-pages-sid-2022-js": () => import("./../../../src/pages/sid-2022.js" /* webpackChunkName: "component---src-pages-sid-2022-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */)
}

